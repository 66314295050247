import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://blog.presik.com/wp-json/wp/v2/posts?per_page=3');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  // Función para limpiar el HTML y evitar XSS
  const cleanHtml = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };

  return (
    <div className='container mx-auto'>
      <h2 className='font-lato text-blue-presik md:text-6xl text-4xl uppercase font-semibold text-center'>Nuestro blog</h2>
      <div className='grid md:grid-cols-3 gap-x-10 justify-between mt-8'>
        {posts.map(post => (
          <div key={post.id} className='bg-gray-50 shadow-sm border border-gray-100 overflow-hidden rounded-md'>
            <img className='w-full' src={post.yoast_head_json['og_image'][0].url || ""} alt={post.title.rendered} />
            <div className='p-4'>
              <a href={post.link} target='_blank'>
                <h2 className='font-lato text-bluePresik lg:text-base uppercase  font-semibold text-left mb-3'>
                  {post.title.rendered}
                </h2>
              </a>
              <p className='text-gray-600 text-base hidden md:block font-roboto' dangerouslySetInnerHTML={{ __html: cleanHtml(post.excerpt.rendered) }} />
            </div>
          </div>
        ))}
      </div>
      <a
	className="font-roboto w-max uppercase mx-auto   flex gap-4 items-center text-2 lg:text-xl mt-8 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
	href="https://blog.presik.com/"
    target='_blank'
	>Ver blog <ArrowLongRightIcon className="h-8 text-black hover:scale-125" /></a>
    </div>
  );
};

export default Blog;
