const styleCounter =
  "text-center uppercase space-y-1 md:space-y-3 bg-blue-presik md:px-3 md:py-8 py-5 px-5 text-white rounded-md shadow-md relative flex flex-col items-center align-middle justify-center";
const styleCounterCloud =
  "text-center uppercase space-y-3 bg-cloud px-5 pt-10 pb-32 text-white rounded-md shadow-md relative";

const CounterExperience = ({
  dataCounter,
  columns = "md:grid-cols-4 grid-cols-2 ",
  pathners = false,
}) => {
  if (pathners) {
    return (
      <div className=" max-w-7xl mx-auto space-y-16 pt-0 md:pt-20">
        <h2 className="mb-10 w-1/3 font-semibold text-blue-presik lg:text-3xl text-4xl lg:w-full relative text-yellow-300 leading-none max-w-2xl mx-auto uppercase text-center">
          Cada dia{" "}
          <span className="font-semibold text-white bg-blue-presik uppercase  px-2 mx-2">
            estadisticas
          </span>{" "}
          junto con nuestros clientes
        </h2>

        <div
          className={`grid md:grid-cols-4 grid-cols-2 mx-auto gap-10 -mb-20`}
        >
          <div className={`${styleCounter}`}>
            <span className="text-6xl font-bold font-lato">
              {dataCounter?.countries.number}
            </span>
            <h3 className="text-yellow-300 font-semibold font-roboto">
              {dataCounter?.countries.name}
            </h3>
          </div>
          <div className={`${styleCounter} `}>
            <span className="text-6xl font-bold font-lato">
              {dataCounter?.customers.number}
            </span>
            <h3 className="text-yellow-300 font-semibold font-roboto">
              {dataCounter?.customers.name}
            </h3>
          </div>
          <div className={`${styleCounter}`}>
            <span className="text-6xl font-bold font-lato">
              {dataCounter?.invoices.number}
            </span>
            <h3 className="text-yellow-300 font-semibold font-roboto">
              {dataCounter?.invoices.name}
            </h3>
          </div>
          <div className={`${styleCounter} `}>
            <span className="text-6xl font-bold font-lato">
              {dataCounter?.integrations.number}
            </span>
            <h3 className="text-yellow-300 font-semibold font-roboto">
              {dataCounter?.integrations.name}
            </h3>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className=" max-w-6xl mx-auto space-y-16 z-10">
        <h2 className="font-lato mb-10 w-full font-normal text-blue-presik lg:text-6xl text-4xl lg:w-full relative text-yellow-300 leading-none max-w-7xl mx-auto uppercase text-center">
          Cada dia <span className="font-semibold">Crecemos</span> junto con{" "}
          <span className="font-semibold">nuestros clientes</span>
        </h2>

        <div
          className={`grid md:grid-cols-4 grid-cols-2 mx-auto gap-10 md:pt-20`}
        >
          <div className={`${styleCounter} `}>
            <span className="text-4xl md:text-6xl flex justify-center -space-x-1 font-semibold">
              <span className="font-normal">+</span>
              <span>{dataCounter?.countries.number}</span>
            </span>
            <h3 className="text-yellow-300 font-semibold md:text-base text-xs">
              {dataCounter?.countries.name}
            </h3>
          </div>
          <div className={`${styleCounter} item`}>
            <span className="text-4xl md:text-6xl flex justify-center -space-x-1 font-semibold">
              <span className="font-normal">+</span>
              {dataCounter?.customers.number}
            </span>
            <h3 className="text-yellow-300 font-semibold md:text-base text-xs">
              {dataCounter?.customers.name}
            </h3>
          </div>
          <div className={`${styleCounter}`}>
            <span className="text-4xl md:text-6xl flex justify-center -space-x-1 font-semibold">
              <span className="font-normal">+</span>
              {dataCounter?.invoices.number}
            </span>
            <h3 className="text-yellow-300 font-semibold md:text-base text-xs">
              {dataCounter?.invoices.name}
            </h3>
          </div>
          <div className={`${styleCounter} `}>
            <span className="text-4xl md:text-6xl flex justify-center -space-x-2 font-semibold">
              <span className="font-normal">+</span>
              {dataCounter?.integrations.number}
            </span>
            <h3 className="text-yellow-300 font-semibold  md:text-base text-xs">
              {dataCounter?.integrations.name}
            </h3>
          </div>
        </div>

        <div>
          <ul className="grid md:grid-cols-4 grid-cols-2  mx-auto z-10 relative  gap-x-2 divide-x divide-yellow-500  md:gap-y-6 gap-y-2 justify-start items-center text-xs md:text-lg text-center">
            <li className="flex  text-bluePresik font-roboto  justify-center font-medium px-3  py-2 ">
              {" "}
              <span>Colombia</span>
            </li>
            <li className="flex  text-bluePresik font-roboto  justify-center font-medium px-3  py-2 ">
              {" "}
              <span>Panamá</span>
            </li>
            {/* <li className="flex  text-bluePresik font-roboto  justify-center font-medium px-3  py-2 "> <span>México</span></li> */}
            <li className="flex  text-bluePresik font-roboto  justify-center font-medium px-3  py-2 ">
              {" "}
              <span>República Dominicana</span>
            </li>
            <li className="flex  text-bluePresik font-roboto  justify-center font-medium px-3  py-2 ">
              {" "}
              <span>Miami</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
};

export default CounterExperience;
