export default [{
        name: 'solutions',
        childs: [
            'tryton',
            'dashboard',
            'electronic_billing',
            'electronic_payroll',
            'integrations',
        ],
    },
    {
        name: 'about_us',
        childs: ['team', 'faq', 'contact'], // + success_histories
    },
    // {
    //     name: 'tell-us-your-dreams',
    //     childs: [],
    // },
    {
        name: 'help',
        childs: ['documentation', 'resources', 'support'],
    },
  
];