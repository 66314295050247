import React, { useState, useEffect } from "react";
const textBase =
  "Utilizamos cookies propias y de terceros para analizar nuestros servicios y la actividad de la web con el fin de mejorar su contenido y mostrarte publicidad relacionada con tus preferencias en base a un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas visitadas)";

const Cookies = () => {
  const [statusCookie, setStatusCookie] = useState(true);
  const [textPrivacy, setTextPrivacy] = useState(false);

  useEffect(() => {
    const cookieStatus = localStorage.getItem("cookieStatus");
    if (cookieStatus === "accepted" || cookieStatus === "refused") {
      setStatusCookie(false);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieStatus", "accepted");
    setStatusCookie(false);
  };

  const rejectCookies = () => {
    setStatusCookie(false);
    localStorage.setItem("cookieStatus", "refused");
  };

  const closeBanner = () => {
    setTextPrivacy(true);
  };

  return (
    <>
      {statusCookie && (
        <div className="w-screen h-screen flex justify-center items-center fixed z-50 top-0 bg-white bg-transparent-presik px-2">
          <div className="bg-blue-presik md:p-10 p-5 py-10 text-white max-w-3xl rounded-md shadow-md">
            <h2 className="font-medium lg:text-3xl text-4xl lg:w-full relative text-white leading-none max-w-2xl mx-auto uppercase  mb-5 ml-0">
              Políticas de privacidad
            </h2>
            {textPrivacy == false ? (
              <p className=" text-lg">{textBase}</p>
            ) : (
              <p className="text-lg max-h-96 overflow-y-scroll scroll-pl-6 pr-3">
                Esta Política de Cookies describe cómo Presik utiliza cookies y
                tecnologías similares en su sitio web ubicado en presik.com. Al
                acceder y utilizar nuestro sitio web, usted acepta el uso de
                cookies de acuerdo con esta política.
                <br />
                <br />
                <span className="font-medium text-yellow-300">¿Qué son las cookies?</span>
                <br />
                Las cookies son pequeños archivos de texto que se almacenan en
                su dispositivo (como su computadora, teléfono móvil u otro
                dispositivo) cuando visita nuestro sitio web. Las cookies se
                utilizan ampliamente para que los sitios web funcionen de manera
                eficiente y para proporcionar información a los propietarios del
                sitio.
                <br />
                <br />
                <span className="font-medium text-yellow-300">¿Cómo utilizamos las cookies?</span>
                <br />
                <br />
                Utilizamos cookies por varias razones, incluyendo:
                <br />
                Funcionalidad del sitio web: Utilizamos cookies para garantizar
                que nuestro sitio web funcione correctamente y le brinde una
                experiencia óptima de usuario. Estas cookies son esenciales para
                el funcionamiento del sitio y le permiten navegar por el sitio y
                utilizar sus características.
                <br />
                <br />
                Análisis y rendimiento: Utilizamos cookies para recopilar
                información sobre cómo los visitantes interactúan con nuestro
                sitio web y para realizar análisis estadísticos. Esto nos ayuda
                a mejorar continuamente nuestro sitio y proporcionar contenido
                relevante y personalizado. Las cookies de análisis nos permiten
                recopilar datos, como la cantidad de visitantes, la forma en que
                los visitantes llegaron a nuestro sitio y las páginas que
                visitaron.
                <br />
                <br />
                Publicidad personalizada: Podemos utilizar cookies para mostrar
                anuncios personalizados en nuestro sitio web y en otros sitios
                web que visite. Estas cookies recopilan información sobre sus
                hábitos de navegación y nos permiten mostrarle anuncios
                relevantes basados en sus intereses.
                <br />
                <br />
                <span className="font-medium text-yellow-300">¿Qué tipos de cookies utilizamos?</span>
                <br />
                Cookies esenciales: Estas cookies son necesarias para
                proporcionarle servicios disponibles a través de nuestro sitio
                web y para utilizar algunas de sus funciones. Sin estas cookies,
                los servicios que ha solicitado no se pueden proporcionar.
                <br />
                <br />
                Cookies de rendimiento: Estas cookies nos ayudan a mejorar el
                rendimiento y la funcionalidad de nuestro sitio web. Nos
                permiten contar las visitas y fuentes de tráfico, para que
                podamos medir y mejorar el rendimiento de nuestro sitio. Estas
                cookies no recopilan información que lo identifique
                personalmente.
                <br />
                <br />
                Cookies de funcionalidad: Estas cookies se utilizan para
                reconocerlo cuando regrese a nuestro sitio web y para recordar
                las elecciones que ha hecho previamente. Esto nos permite
                personalizar nuestro contenido para usted y recordar sus
                preferencias (por ejemplo, su nombre de usuario, idioma o
                región).
                <br />
                <br />
                <span className="font-medium text-yellow-300">¿Cómo puede controlar las cookies?</span>
                <br />
                <br />

                Puede administrar las cookies a través de la configuración de su
                navegador y eliminar las cookies existentes en cualquier
                momento. Tenga en cuenta que la desactivación de las cookies
                puede afectar la funcionalidad de nuestro sitio web y limitar su
                capacidad para utilizar ciertas características.
                <br />
                <br />
                Tenga en cuenta que también podemos permitir que terceros
                seleccionados utilicen cookies en nuestro sitio web para
                diversos fines, como proporcionar servicios de análisis y
                publicidad. Estas cookies están sujetas a las políticas de
                privacidad de los terceros correspondientes y no están cubiertas
                por esta Política de Cookies.
                <br />
                <br />
                <span className="font-medium text-yellow-300">Cambios en nuestra Política de Cookies</span>
                <br />
                <br />
                Nos reservamos el derecho de modificar esta Política de Cookies
                en cualquier momento. Cualquier cambio se publicará en esta
                página con una fecha de entrada en vigor actualizada. Le
                recomendamos
              </p>
            )}
            <div className="flex flex-wrap  gap-2 md:gap-3 mt-8">
              <button
                className="bg-yellow-300 text-blue-presik rounded-full md:px-8 px-5 py-2"
                onClick={acceptCookies}
              >
                Aceptar y navegar
              </button>
              <button
                className="bg-white text-blue-presik rounded-full md:px-8 px-5 py-2"
                onClick={rejectCookies}
              >
                Rechazar
              </button>
              {!textPrivacy && (
                <button
                  className="bg-white text-blue-presik rounded-full md:px-8 px-5 py-2"
                  onClick={closeBanner}
                >
                  Políticas de Cookies
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookies;
