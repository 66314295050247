import React from 'react'; 
import {useTranslation} from 'react-i18next'; 
import i18next from 'i18next'; 
import pseImg from 'Assets/images/pse.png'; 
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

const LangMenu = ({styleText}) => { 
 // Use the useTranslation hook to get the t function for translating strings
 const {t, i18n} = useTranslation();
 const lan = i18n;

// Define a function that takes a language parameter & Call the i18next library's changeLanguage function with the selected language

	function handleClick(lang) { 
		i18next.changeLanguage(lang);
	}
	
	return(<>
	<ul className='flex space-x-3 relative items-center'>
			<li className={`hidden transition-all duration-100 px-3 pt-5 lg:pt-0 md:flex justify-center items-center text-lg ${styleText} rounded-b-md  hover:text-black  cursor-pointer relative`}>{i18n.language === "es" ? "EN" : "ES"}</li>
			<li className='text-sm w-32 lg:text-sm md:mt-0 bg-yellow-300 lg:w-max rounded-full py-2 px-3 md:px-6 justify-center hover:scale-110 duration-100 ease-linear transform hover:text-black flex gap-4 items-center md:w-52'><a href="/about_us/contact"  className="text-inherit hover:text-black">CONTÁCTANOS</a></li>
			<li className='text-sm  lg:text-sm md:mt-0 md:ring-1 text-yellow-300 hover:bg-yellow-300  ring-yellow-300 lg:w-max rounded-full md:py-2  md:px-6 justify-center hover:scale-110 duration-100 ease-linear transform  flex gap-4 items-center md:w-52'><a href="https://cloudx2.presik.com/loginCustomer"  target='_blank' className="text-inherit hover:text-black"><UserCircleIcon className='w-9 md:hidden text-bluePresik' /><span className='hidden md:block'>INICIO DE SESIÓN</span></a></li>
		</ul>
		{/* <Menu> 
		<Menu.Button className="flex text-lg font-medium text-gray-600 px-3 items-center py-1 rounded-md hover:bg-gray-200 gap-2">
			{t("menu.lang")} <ChevronDownIcon className="h-4 w-4 text-gray-600"/></Menu.Button> 
			<Menu.Items className="lg:top-14 top-16 border-2 border-gray-200 absolute flex flex-col w-28 rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none"> 
			<Menu.Item className="py-1 hover:bg-gray-200" onClick={() => handleClick("en")}> 
				<button type='button'>English</button> 
			</Menu.Item>
			<Menu.Item className="py-1 hover:bg-gray-200"  onClick={() => handleClick("es")}> 
				<button type='button'>Español</button> 
			</Menu.Item>
			</Menu.Items>
		</Menu>  */}
		{/* End the Headless UI Menu component */}
		{/* <a href="/pse_payment" className="logo-pse"><img src={pseImg} alt='pse' className='h-10 w-10 md:ml-5 ml-2' /></a>  */}
		</>
	
	
);}

export default LangMenu;

