import React, { useState } from "react";
import {
  CalculatorIcon,
  CurrencyDollarIcon,
  ShoppingBagIcon,
  PresentationChartBarIcon,
  TableCellsIcon,
  Cog6ToothIcon,
  UsersIcon,
  CalendarDaysIcon,
  CheckCircleIcon
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const icons = {
  "calculator": CalculatorIcon,
  "dolly": CurrencyDollarIcon,
  "address card": UsersIcon,
  "shipping fast":  ShoppingBagIcon,
  "chart line": PresentationChartBarIcon,
  "dolly flatbed": TableCellsIcon,
  "users": CalendarDaysIcon,
  "wrench": Cog6ToothIcon,
  "check": CheckCircleIcon
};

const OtherModules = (props) => {
  const { data, lang } = props;
  const [dataModule, setDataModule] = useState(data.slice(0, 8));

  const getModulesActive2 = (item, i) => {
   
    let title = lang(`modules.${item.name}`);
    let desc2 = item.shortDesc
    const longitudMaxima = 60;

    return (
      <div
        key={i}
        className="flex flex-col -mt-5  px-6 py-0 rounded-full text-center lg:cursor-pointer transform 0 lg:hover:shadow-sm   lg:hover:scale-105 ease-linear transition-all duration-100 sm:h-80 sm:w-80 h-44 w-44 items-center justify-center hover:bg-gray-50 point"
      >
        <div className="">
          {/* {getIconModule(icons[item?.icon])} */}
          {getIconModule2(item?.icon)}
          {/* {icons["calculator"]} */}

          <a
            href="/"
            className="font-lato text-bluePresik  lg:text-base uppercase text-gra-600 font-semibold text-center"
          >
            {title || "sass"}
          </a>
        </div>
        {/* <p className="text-cyan-900 text-base hidden md:block">{desc}</p> */}
        <p className="text-gray-600 text-base hidden md:block font-roboto" >{desc2}</p>
      </div>
    );
  };

  const getIconModule2 = (icon) => {
    // return false;
    const ComponentIcon = icons[icon];
    return (
      <div className="w-full flex justify-center relative mb-3">
        {/* <div className="absolute h-12 w-12  z-20 uppercase rounded-md bg-yellow-400 transform rotate-45 opacity-50 group-hover:rotate-90 group-hover:bg-blue-600 transition-all duration-500  top-0"></div> */}
        {ComponentIcon && (
          <ComponentIcon className="w-12 h-12 text-bluePresik  rounded-full z-30 relative" />
        )}
      </div>
    );
  };

  

  return (
    <div className="mb-20"> 
    

      <div className="grid lg:grid-cols-4 grid-cols-2 lg:gap-x-44  gap-x-4 justify-items-center  lg:text-lg text-xs text-gray-600 max-w-6xl mx-auto  pt-10">
        {dataModule.map((m, i) => getModulesActive2(m, i))}
      </div>
    
      <Link to="/solutions/tryton" className="font-roboto w-max mx-auto uppercase flex gap-4 items-center text-2 lg:text-xl mt-4 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black">más información</Link>
    </div>
  );
};

export default OtherModules;
