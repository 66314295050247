import React from "react";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";
import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import PageHeroStandard from "Components/PageHeroStandard";
import team from "Assets/images/empresario.jpeg";
import Section from "Components/Section";
import ImagePc from "Assets/images/176shots_so.png"
import { Link } from "react-router-dom";
import { ArrowLongRightIcon, CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import CountdownTimer from "Components/CountdownTimer";
import ImgCLoudVsPresik from "Assets/images/backgroud-cloubvspresi.jpeg"


const services = [
  { name: "Vinculación con diferentes channel mánager", presik: true, cloudbeds: true },
  { name: "Vinculación con todas las OTAS", presik: true, cloudbeds: true },
  { name: "Interfaz web intuitiva", presik: true, cloudbeds: true },
  { name: "Personalización de reportes", presik: true, cloudbeds: true },
  { name: "CRM", presik: true, cloudbeds: true },
  { name: "Motor de reservas", presik: true, cloudbeds: true },
  { name: "Informe de cierre de caja en tiempo real", presik: true, cloudbeds: true },
  { name: "Notas en reserva", presik: true, cloudbeds: true },
  { name: "Pagos electrónicos", presik: true, cloudbeds: true },
  { name: "Procesamiento de tarjetas de crédito", presik: true, cloudbeds: true },
  { name: "Multi idioma", presik: true, cloudbeds: true },
  { name: "Inventarios", presik: true, cloudbeds: false },
  { name: "Atención inmediata y personalizada (24/7)", presik: true, cloudbeds: false },
  { name: "Vinculación en el SIAT", presik: true, cloudbeds: false },
  { name: "Reporte del SIRE", presik: true, cloudbeds: false },
  { name: "Contabilidad", presik: true, cloudbeds: false },
  { name: "Transferencia de cargos a folios", presik: true, cloudbeds: false },
  { name: "Ajuste de reporte a la medida", presik: true, cloudbeds: false },
  { name: "Aplicativo ama de llaves", presik: true, cloudbeds: false },
  { name: "Auditoria nocturna", presik: true, cloudbeds: false },
  { name: "Automatización de comisiones (OTAS Y AGENTES)", presik: true, cloudbeds: false },
  { name: "Automatización de la facturación electrónica", presik: true, cloudbeds: false },
  { name: "Compras", presik: true, cloudbeds: false },
  { name: "Automatización y confirmación de la reserva", presik: true, cloudbeds: false },
  { name: "Seguimiento de la bitácora de recepcionistas", presik: true, cloudbeds: false },
  { name: "Gestión de mantenimiento", presik: true, cloudbeds: false },
  { name: "Límite de usuarios", presik: "Ilimitados", cloudbeds: false },
  { name: "Aplicativo web checkin", presik: true, cloudbeds: false },
  { name: "Manejo de centro de costos", presik: true, cloudbeds: false },
  { name: "Integración de inteligencia de negocios (Tableu y Metabase)", presik: true, cloudbeds: false },
  { name: "Aplicativo de housekeeping", presik: true, cloudbeds: false },
];
// import { motion } from "framer-motion"

function LandingHotel() {
  const { t } = useTranslation();
  const targetDate = new Date(new Date(2024, 4, 28)); // 5 minutos desde ahora

  const FormSuscription = () => {
    return (
      <div className="mx-auto text-white text-center">
       <p className="text-2xl">Hasta el 28 junio recibe el 50% de descuento del valor actual de tu proveedor y paga  0 pesos de implementación.</p>
      </div>
    )
  }


const TableServices = () => {
  const Check = () => {
   return ( <CheckCircleIcon className="w-10 mx-auto text-bluePresik stroke-green-500" />)
  }
  const Xmark = () => {
    return ( <XCircleIcon className="w-10 mx-auto text-bluePresik stroke-red-500" />)
   }
  return (
    <div className="container mx-auto max-w-4xl">
      <table className="min-w-full bg-white relative">
        <thead className="bg-bluePresik  text-yellow-500 text-xl sticky top-16">
          <tr>
            <th className="w-3/5 py-2 ">Servicio</th>
            <th className="w-1/5 py-2">Presik</th>
            <th className="w-1/5 py-2">Cloudbeds</th>
          </tr>
        </thead>
        <tbody>
          {services.map((item, index) => (
            <tr key={index} className="text-center">
              <td className="border px-4 py-2 text-xl font-medium">{item.name}</td>
              <td className="border px-4 py-2">{item.presik === true ? <Check />  : item.presik}</td>
              <td className="border px-4 py-2">{item.cloudbeds === true ?  <Check /> : (item.cloudbeds === false ? <Xmark /> : item.cloudbeds)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

  return (
    <PageStandard buttonContactUs={false}>
      {/* <PageHeroStandard backgroundHero="imageRoundHeroText" image={team}>
        <PageTitle title="tell-us-your-dreams.title" color="dark" />
        <PageDescription
          text="Cuéntanos tus ideas, que nosotros te ayudamos a materializar tus sueños."
          color="dark"
        />
      </PageHeroStandard> */}
      <Section spaceTop="p-0 px-0" fullContainer={true}>
        <img src={ImgCLoudVsPresik} className="w-full h-full object-cover" />
      </Section>
 
      <Section spaceTop="pt-10 pb-24" style="flex  justify-items-stretch items-center ">
        <div  className="w-[60%] pr-20">
          <h1 className="pt-12 md:mt-8 text-bluePresik lg:text-5xl text-4xl uppercase mb-8 font-lato">
            La mejor alternativa e inteligente a Cloudbeds en Colombia
          </h1>
          <p className="font-roboto lg:text-2xl text-xl font-normal text-left">
            Presik se posiciona como una de las principales alternativas para
            reemplazar a Cloudbeds, siendo altamente buscada por su simplicidad
            y funciones especializadas diseñadas específicamente para cada
            hotel. Con una interfaz intuitiva y de fácil acceso. Nuestra promesa
            de valor es un soporte rápido y efectivo, donde contestaremos en un
            lapso a 10 minutos para cada solicitud, durante los 365 dìas, y las
            24 horas del día y 7 días de la semana. Hasta el 28 junio recibe el
            50% de descuento del valor actual de tu proveedor y paga 0 pesos de
            implementación.
          </p>
          <Link
            className="font-roboto w-max uppercase flex gap-4 items-center text-black lg:text-xl mt-8 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
            to="/about_us/contact"
          >
            {" "}
            Recibir más información{" "}
            <ArrowLongRightIcon className="h-8 text-black hover:scale-125" />
          </Link>
        </div>
        <div className="flex justify-end  max-w-[40%]" >
          <img src={ImagePc} className=" "/>
        </div>
      </Section>
      <Section bgColor="bg-bluePresik" spaceTop="py-10">
        <FormSuscription />
      </Section>
      <Section spaceTop="py-10">
      <CountdownTimer targetDate={targetDate} />
      </Section>
      <Section spaceTop="pt-10 pb-10" bgColor="bg-gray-50">
        <h2 className="font-lato font-light lg:text-3xl  text-2xl lg:w-full relative text-bluePresik leading-none max-w-3xl mx-auto uppercase text-center mb-10">
        Características adicionales que hacen que nuestros clientes se enamoren aún más 
        </h2>
        <TableServices />
      </Section>
      <Section>
        FormYourDreams
      </Section>
    </PageStandard>
  );
}

export default LandingHotel;
