import React from 'react';
import {useTranslation} from 'react-i18next';
import dataMenu from './data';
import presikImg from 'Assets/images/logo-presik.png';
import presikImgWhite from 'Assets/images/logo-white.webp';
import { Menu } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/solid';
import DropdownMenu from './Dropdown';
import LangMenu from './LangMenu';

const Navbar = ({mode = "dark"}) => {

// Import the useTranslation hook and destructure the t function from the returned object
const {t} = useTranslation();	
let style = ""
let styleText = ""
let borderStyle = ""
if(mode == "dark"){
	style = "bg-blue-presik";
	styleText= "text-white";
	


}
else{
	style = "bg-white";
	styleText = "text-gray-900";
	borderStyle = "border-b-2 border-gray-100"

}

//Bring logo of the company
function setPresikImg(mode) {

if(mode == "dark"){
	return (
		<a href="/" id="menu-link-logo">
			<img src={presikImgWhite} id="menu-logo-presik" alt="presik-logo" className='h-6 md:h-12 object-contain' />
		</a>
	);
}
else{
	return (
		<a href="/" id="menu-link-logo">
			<img src={presikImg} id="menu-logo-presik" alt="presik-logo" className='h-6 md:h- object-contain' />
		</a>
	);
}
}	

return (
<header className='container z-50 relative'>
	
	{/** Moblie and tablet view */}
<nav className={`flex top-0 lg:hidden shadow-md h-16 md:h-20 justify-between items-center w-screen px-2 fixed z-50 bg-white`}>

	
<div className="flex items-center ">
		<Menu>
			<Menu.Button>
				<Bars3Icon className='h-12 w-12 px-2 py-1 mr-2 hover:bg-gray-200 rounded-md' />
			</Menu.Button>
			<Menu.Items>
				<div className={`fixed top-16 left-0 bg-white z-20 ${borderStyle} md:rounded-md md:w-44 w-screen`}>
					{dataMenu.map((d, i) => d.link ?
				(	<div className="relative py-6" key={i}>
					<a href={d.link}><span>{t(`menu.${d.name}`)}</span></a>
					</div>
				) : (
					<Menu.Item key={i}>
						 <DropdownMenu menu={d} childs={d.childs} t={t}/>
					</Menu.Item>))}
				</div>
			</Menu.Items>
		</Menu>
		{setPresikImg()}
	</div>
	<LangMenu styleText={styleText}/>
</nav>

{/**Desktop view */}
<nav className={`hidden w-full bg-white ${borderStyle} px-28 fixed top-0 z-40 h-16 lg:flex justify-between items-center ${style}`}>
	{setPresikImg(mode)}
	<div className='flex items-center md:space-x-4 font-roboto'>
		<div className='flex gap-5'>
			{dataMenu.map((d, i) => d.link ? ( 
			<div className="relative" key={i}>
				<a href={d.link}><span>{t(`menu.${d.name}`)} </span></a>
			</div>
		) : ( 
			<div className="relative" key={i}>
			<DropdownMenu menu={d} childs={d.childs} t={t}   styleText={styleText} />
		</div> ))}
		<a href='https://blog.presik.com/' target='_blank'  className='font-roboto group text-lg w-full flex justify-between items-center text-white font-normal   px-2 py-3 lg:py-0'>Blog</a>

		
	</div>
	<LangMenu styleText={styleText}/>
	</div>
</nav>
</header>
)}

export default Navbar;