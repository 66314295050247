import React from "react";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import PageStandard from "Components/Page";
//import PageYellowImg from "Assets/images/page-yellow.png";
import PageTitle from "Components/PageTitle";
import faqData from "./faq_data";
import PageHeroStandard from "Components/PageHeroStandard";
import "./FAQ.css";
import Section from "Components/Section";
import team from "Assets/images/future2.png";
import { Link } from "react-router-dom";
import {
  ArrowLongRightIcon,
  QuestionMarkCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";

export default function FAQ() {
  const { t } = useTranslation();
  const itemCircle = () => {
    return (
      <div className="rounded-full border border-yellow-300 p-1 w-6 h-6 md:flex justify-center items-center hidden ">
        <div className="w-3 h-3 bg-yellow-300 rounded-full relative top-0 animate-ping animate-infinite animate-duration-1000 animate-delay-100 animate-ease-linear animate-normal"></div>
      </div>
    );
  };
  return (
    <PageStandard buttonContactUs={false}>
      <PageHeroStandard backgroundHero="imageRoundHeroText" roundedBottom={true} image={team}>
        <PageTitle title="faq.title" />
        <div className="flex justify-center">
          <Link
            className="font-roboto w-max uppercase flex gap-4 items-center text-black lg:text-xl mt-8 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
            to="/about_us/contact"
          >
            {" "}
            Contáctanos{" "}
            <ArrowLongRightIcon className="h-8 text-black hover:scale-125" />
          </Link>
        </div>
      </PageHeroStandard>
      <Section fullContainer={true} spaceTop="t-10" spaceX="px-0">
        {Object.keys(faqData).map((a, i) => (
          <div
            key={i}
            className={` md:py-20 py-10 lg:px-30 left-full overflow-hidden px-5 ${
              i % 2 == true ? "" : "bg-gray-50"
            }bg-gray-50`}
          >
            <div className="container md:mx-auto md:flex justify-between ">
              <div className="md:flex md:justify-between items-center md:w-1/3 md:pr-10">
                <h2
                  className="mb-10 md:mb-0 font-lato font-semibold lg:text-1xl text-2xl flex uppercase w-full md:text-white text-bluePresik  md:bg-bluePresik bg-yellow-300 p-2 rounded-md  justify-between"
                  key={a}
                >
                  {t(`faq.${a}`)} <QuestionMarkCircleIcon className="w-8" />
                </h2>
                <div className="border-b border-yellow-300  w-full hidden md:block"></div>
                {itemCircle()}
              </div>

              <div className="md:w-2/3">
                {faqData[a].map(([q, r], i) => (
                  <Disclosure defaultOpen={i == 0 ? true : false}>
                    {({ open }) => (
                      /* Use the `open` state to conditionally change the direction of an icon. */
                      <>
                        <Disclosure.Button
                          as="h2"
                          className={`group relative flex justify-between text-xl cursor-pointer font-lato py-3 px-4 ${!open ? "bg-bluePresik text-white" : "bg-yellow-300 text-bluePresik"}   mb-3 rounded-md hover:bg-yellow-300 hover:text-bluePresik`}
                        >
                          {t(`faq.${q}`)}{" "}
                          <ChevronDownIcon
                            className={`${open ? "rotate-180 transform" : ""} absolute md:static top-4 right-3 md:w-8 w-6 text-yellow-300 group-hover:text-bluePresik`}
                          />
                          {/* {t(`faq.${q}`)} <ChevronDownIcon  className={`${open ? 'rotate-90 transform' : ''} w-8`} /> */}
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-gray-600 py-5 font-roboto text-[16px]">
                          {t(`faq.${r}`)}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Section>
    </PageStandard>
  );
}
