import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import presikImg from "Assets/images/logo-white.png";
/* import imagePhoneImg from "Assets/images/asesor-presik.png"; */
import dataMenu from "Menu/data.js";
import { PhoneIcon } from "@heroicons/react/24/solid";

const STYLE_CODE = " text-yellow-500 font-500";
const STYLE_BUTTON_SOCIAL = "bg-yellow-300 text-bluePresik p-2 w-10 h-10 flex items-center align-middle justify-center  transition-all duration-150 hover:w-16 hover:justify-end" 

function Footer() {
  const { t } = useTranslation();

  const ContainerCode = ({ children }) => {
    return (
      <span className="space-x-2">
        <span className={STYLE_CODE}>[</span>
        <span>{children}</span>
        <span className={STYLE_CODE}>]</span>
      </span>
    );
  };
  const BtnsSocial = () => {
    return (
      <div className="fixed z-50  left-0 top-1/3">
        <ul className="flex flex-col space-y-2 ">
          <li className={STYLE_BUTTON_SOCIAL}>
            <a href="https://www.facebook.com/presiksas/" target="_blank" className="flex justify-center items-center">
              <i className="fi fi-brands-facebook text-xl duration-100 relative top-1 relative top-1"></i>
            </a>
          </li>
          <li className={STYLE_BUTTON_SOCIAL}>
            <a
              href="https://www.instagram.com/presik_smart_tech/"
              target="_blank"
            >
              <i className="fi fi-brands-instagram duration-100 text-xl relative top-1"></i>
            </a>
          </li>
          <li className={STYLE_BUTTON_SOCIAL}>
            <a href="mailto:ejecutivadecuenta@presik.com">
              <i className="fi fi-sr-envelope text-xl duration-100 relative top-1"></i>
            </a>
          </li>
          <li className={STYLE_BUTTON_SOCIAL}>
            <a
              href="https://www.youtube.com/channel/UC0AB6nt2WOmihWVlNPBl0Mw/"
              target="_blank"
            >
              <i className="fi fi-brands-youtube duration-100 text-2xl relative top-1"></i>
            </a>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Fragment>
      <footer className="bg-bluePresik text-white w-full pt-10 px-5 pb-5 font-roboto ">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-9 px-5 md:px-10">
            <div className="grid col-span-2 lg:col-span-4">
              <div className="grid grid-cols-2">
                {" "}
                {dataMenu.map((d, i) => (
                  <div className="col-span-1" key={i}>
                    <p className="text-white font-semibold text-xl">
                      <ContainerCode>{t(`menu.${d.name}`)}</ContainerCode>
                    </p>
                    <ul>
                      <li
                        className="flex flex-col px-2 md:px-0 py-8 gap-1 md:gap-2"
                        key={i}
                      >
                        {d.childs.map((m, k) => (
                          <a
                            className="text-gray-400 hover:text-yellow-300 cursor-pointer font-medium text-base"
                            href={`/${d.name}/${m}`}
                            key={k}
                          >
                            {" "}
                            {t(`menu.${m}`)}{" "}
                          </a>
                        ))}{" "}
                      </li>{" "}
                    </ul>{" "}
                  </div>
                ))}
                <p className="footer-title py-2 text-xl mb-4 font-semibold">
                  <ContainerCode>
                    {t("footer.contact_information")}
                  </ContainerCode>
                  <span className="pb-2 block pt-5 text-gray-400">
                    {" "}
                    {t("footer.address")}{" "}
                  </span>
                </p>
              </div>
            </div>
            <div className="px-1 lg:px-4 col-span-3 mt-10 md:mt-0">
              <ul className="w-1/2">
                {/* <li className="pb-2 text-gray-400"> {t("footer.address")} </li>{" "} */}
                <li className="pt-2 pb-5 text-xl font-semibold">
                  <ContainerCode>{t("footer.sales")}</ContainerCode>
                </li>{" "}
              </ul>
              <div className="flex justify-between">
                <ul>
                  <li className="pt-2 pb-2 text-lg font-medium">Colombia</li>
                  <li className="text-lg items-center text-gray-300 flex gap-3">
                    <PhoneIcon className="h-5 transform -rotate-90" />
                    (+57) 688 4208{" "}
                  </li>{" "}
                  <li className="text-lg items-center text-gray-300 flex gap-3">
                    <PhoneIcon className="h-5 transform -rotate-90" />
                    (+57) 324 584 0949{" "}
                  </li>{" "}
                  <li className="text-lg items-center text-gray-300 flex gap-3">
                    <PhoneIcon className="h-5 transform -rotate-90" />
                    (+57) 301 439 7998{" "}
                  </li>
                  <li className="pt-2 pb-2 text-lg font-medium">
                    República Dominicana
                  </li>
                  <li className="text-lg items-center text-gray-300 flex gap-3">
                    <PhoneIcon className="h-5 transform -rotate-90" />
                    (+57) 688 4208{" "}
                  </li>
                </ul>
                <ul className="w-1/2">
                  <li className=" pb-2 text-lg font-medium">Miami</li>
                  <li className="text-lg items-center text-gray-300 flex gap-3">
                    <PhoneIcon className="h-5 transform -rotate-90" />
                    +1 786 818 5734{" "}
                  </li>
                  <li className=" pb-2 text-lg font-medium">Panamá</li>
                  <li className="text-lg items-center text-gray-300 flex gap-3">
                    <PhoneIcon className="h-5 transform -rotate-90" />
                    +507 6458-2466{" "}
                  </li>
                </ul>{" "}
              </div>
            </div>
            <div className="col-span-2 px-0 lg:px-4">
              <img
                src={presikImg}
                id="footer-logo-presik"
                alt="logo"
                className="h-10 mb-4"
              />
              <p className="text-gray-300 lg:w-52">
                {" "}
                {t("footer.description_presik")}{" "}
              </p>{" "}
            </div>
          </div>
        </div>
        <div>
          <p className="text-gray-300 text-center text-base font-medium ">
            {" "}
            <span className="text-2xl"> &copy; </span> Todos los derechos
            reservados Presik Copyright 2024{" "}
          </p>
        </div>
      </footer>
      <BtnsSocial />
    </Fragment>
  );
}

export default Footer;
